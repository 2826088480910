import Fetch from '@/utils/fetch.js';
import { course } from '@/api/course/course';
import { ads, activityCommunityGroups, activityCommunityGroupsAll } from '@/api/index';

/**
 * 活动详情
 *  @param {number} id
 *
 * */
const communityGroupSubject = (data, fn, id, errorFn, dataLevel) => {
  new Fetch(
    `activity/community_groups/${id}`,
    'get',
    data,
    fn,
    errorFn,
    dataLevel
  );
};

// 课程商城
const shopCourseList = (data, fn) => {
  course('get', data, fn);
};

// 预测
const predictions = fn => {
  new Fetch('predictions', 'get', '', fn);
};

// 机经广告位
const predictionAds = (data, fn, dataLevel, errorfn) => {
  ads(data, fn, errorfn, dataLevel);
};

export {
  course,
  activityCommunityGroups,
  activityCommunityGroupsAll,
  communityGroupSubject,
  shopCourseList,
  predictions,
  predictionAds
};
