<template>
  <div class="ielts-page">
    <div class="ielts-tab-box">
      <div class="ielts-tab">
        <div class="box"></div>
        <div class="ielts-tab-item" v-for="item in ieltsTab" :key="item.url">
          <router-link :to="item.url">
            <div class="" :class="`icon-papa-${item.icon}`">{{item.name}}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="ielts-content">
      <router-view name="practical"></router-view>
    </div>
    <FloatBar category="practical"></FloatBar>
    <FloatAds></FloatAds>

  </div>
</template>

<script>
  import FloatBar from '@/components/FloatBar/FloatBar.vue'
  import FloatAds from '@/components/FloatAds/FloatAds.vue'

  import * as Api from '@/api/practical/practical'

  export default {
    name: 'Activity',
    components: {
      FloatBar,
      FloatAds
    },
    data () {
      return {
        courseData: [],
        currentIndex: '',
        hideLine:{
          thirdLine:{
            left: true
          }
        }
      }
    },
    computed: {
      courseDetail (item) {
        return (item) => {
          switch (item.course_type) {
            case 1:
              return 'pastCourseDetail'
            case 2:
              return 'courseDetail'
            case 3:
              return 'courseDetail'

          }
        }
      }
    },
    mounted () {
      this.getActivityCourseList()
    },
    methods: {
      getActivityCourseList () {
        let params = {
          category_id: 11,
          type: 2
        }
        Api.shopCourseList(params, res => {
          this.courseData = res
        })
      },
    },
    metaInfo: {
      title: '趴趴教育唯一官方网站 - 雅思,托福,GMAT走心的在线英语培训平台',
      meta: [
        {
          name: 'keywords',
          content: '趴趴雅思,趴趴托福,趴趴GMAT,趴趴雅思小助手,雅思,托福,GMAT,PTE,实用英语'
        }
      ]
    }

  }
</script>

<style lang="less" scoped>
  .practical-page {
    overflow: hidden;
    background: #fff;

    & > .content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 80px;
      & > .lists {
        width: 1200px;
        margin: 80px auto;
        float: left;

        & > .course-card:nth-child(4n) {
          margin-right: 0;
        }

        /deep/ .course-card {
          position: relative;
          width: 272px;
          float: left;
          margin-right: 37px;
          box-sizing: border-box;
          margin-bottom: 30px;
          &>a{
            position: relative;
            border: 1px solid #E6E6E6;
            border-radius: 5px;
            background: #fff;
            height: 294px;
            & > .top {
              width: 100%;
              height: 153px;
              border-radius: 5px 5px 0 0;
            }

            & > .desc {
              box-sizing: border-box;
              padding: 18px 16px;
              margin: 0;
              height: 140px;

              & > .first-line {
                font-size: 16px;
              }

              & > .second-line {
                font-size: 14px;
                margin-bottom: 30px;
              }
              &> .third-line{
                padding: 0 18px;
              }
            }
          }
        }
      }
    }

    .slideInUp {
      top: -10px;
    }
  }
</style>
